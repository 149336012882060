
import { Options, Vue } from "vue-class-component";
import { Prop } from 'vue-property-decorator';
import { useQuery } from "@vue/apollo-composable";
import { Month, DayStatus, MonthReportContractDay, CalendarDay, GetAbsenceReportDocument, AbsenceReportContract } from '@/graphql/generated-operations';

@Options({
  components: {
  }
})
export default class AbsencesCalendarTable extends Vue {

  @Prop()
  month!: Month;

  days: CalendarDay[] = [];
  contracts: AbsenceReportContract[] = [];

  loading = true;

  created() {
    const { onResult } = useQuery(GetAbsenceReportDocument, {
      month: this.month.key,
    });

    onResult(queryResult => {
      this.days = queryResult.data.getAbsenceReport.days;
      this.contracts = queryResult.data.getAbsenceReport.contracts;

      this.loading = false;
    });
  }

  isBlankDay(date: MonthReportContractDay) {
    return date.status == DayStatus.Blank;
  }

  isPartialDay(date: MonthReportContractDay) {
    return date.status == DayStatus.Partial;
  }

  isCompleteDay(date: MonthReportContractDay) {
    return date.status == DayStatus.Complete;
  }

  isEmptyDay(date: MonthReportContractDay) {
    return date.status == DayStatus.Empty;
  }

  getDateBgClass(day: CalendarDay) {
    if ( day.holiday ) {
      return 'bg-blue-100';
    }
    else if ( day.weekend ) {
      return 'bg-gray-100';
    }
    return '';
  }

}
